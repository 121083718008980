import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Layout } from "../layout"
import { Seo } from "../seo"
import CollectionPanel from "../CollectionPanel"
import CategoryProductGrid from "../CategoryProductGrid"

export default function Products() {
  const {
    shopifyCollection: { products },
  } = useStaticQuery(
    graphql`
      query {
        shopifyCollection(handle: { eq: "homepage" }) {
          products {
            id
            title
            tags
            totalInventory
            slug: gatsbyPath(
              filePath: "/shop/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
            )
            media {
              ... on ShopifyMediaImage {
                id
                image {
                  altText
                  gatsbyImageData
                }
              }
            }
            variants {
              compareAtPrice
            }
            priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            vendor
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="All Products" />
      <div className="d-flex">
        <CollectionPanel breakpoint="md" />
        <CategoryProductGrid products={products} />
      </div>
    </Layout>
  )
}
