import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../../components/layout"
import { Seo } from "../../../components/seo"
import CategoryProductGrid from "../../../components/CategoryProductGrid"
import CollectionPanel from "../../../components/CollectionPanel"
import Products from "../../../components/Home"

export default function ProductTypeIndex({
  data: { products },
  pageContext: { productType },
}) {
  if (!productType) return <Products />
  return (
    <Layout>
      <Seo title={`Category: ${productType}`} />
      <div className="d-flex">
        <CollectionPanel breakpoint="md" />
        <CategoryProductGrid products={products.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($productType: String!) {
    products: allShopifyProduct(
      filter: { productType: { eq: $productType, ne: "Lookbook" } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
